import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'

export default () => (
  <Wrapper subtitle='Impressum'>
    <h1>Impressum</h1>

    <b>RST-Engineering GmbH</b><br/><br/>

    Wahlerter Weg 17<br/>
    63628 Bad Soden-Salmünster<br/><br/>

    Telefon: 06056 - 62 15<br/>
    E-Mail: kontakt@rst-info.de<br/><br/>

    Geschäftsführerinnen: Gabriele Werth, Tatjana Werth <br/><br/>

    Amtsgericht Schlüchtern, HRB: 90397<br/>
    USt-ID: DE1135 826 72<br/><br/>

    <p>Alle Inhalte dieser Website einschließlich der Gestaltung und Programmierung unterliegt dem Urheberrecht (Copyright). Alle Angaben ohne Gewähr. Alle Rechte vorbehalten. Alle Informationen dienen zur persönlichen Information - eine kommerzielle Nutzung der redaktionellen Beschreibungen / der Strukturierung ist nicht erlaubt. Nicht erlaubt ist eine kommerzielle Nutzung der Daten, wie zum Beispiel zum Aufbau eigener Systeme und Dienste bzw. Verzeichnisse jeglicher Art. Außerdem ist das automatische Auslesen von Daten durch Software untersagt. Für die Angaben auf dieser Website oder falsche Links wird keine Verantwortung und Haftung übernommen. Durch die Informationen auf dieser Website soll keinerlei rechtliche Beratung erfolgen.</p>

    <p>Unsere Seiten enthalten Verweise (sog. "Links") zu anderen Angeboten im World Wide Web. Für die Inhalte der gefundenen Angebote sind die jeweiligen Betreiber verantwortlich. Wie mit Ihren Daten dort verfahren wird, können wir weder beurteilen noch beeinflussen. Anbieter fremder Inhalte, auf die verwiesen wird, sind selbstverständlich für deren Rechtmäßigkeit selbst verantwortlich; falls Sie aber Angebote finden sollten, die anstößig sind, oder gar rechtswidrigen Inhalt haben - informieren Sie uns, wir werden den Sachverhalt überprüfen und das entsprechende Link löschen bzw. indizieren.</p>

    <h2>Nutzungsbedingungen</h2>

    <p>Das Herunterladen von Bildern auf dieser Webseite ist nicht gestattet. Ebenso eine Weiterveröffentlichung, Weiterübertragung, Reproduktion oder anderweitige Verwendung von sämtlichen Bildern auf dieser Webseite. Der Inhalt dieser Webseite darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder dritten zugänglich gemacht werden. Wir weisen darauf hin, dass auf den Webseiten enthaltene Bilder teilweise dem Urheberrecht Dritter unterliegen</p>
  </Wrapper>
)
